import { useCallback, useRef, useState } from 'react';
import { defaultTableFilters } from '../constants/filters';
import { TableContextType } from '../contexts/TableContext';
import { LandlordsQuery, useLandlordsQuery } from '../graphql';
import { SearchField, TableFilters } from '../types/tableFilters';

interface UseLandlordsProprs {
    take: number;
    page: number;
}

const useLandlords = ({ take, page }: UseLandlordsProprs) => {
    const prevPagesCount = useRef<number>(0);

    const [filter, setFilter] = useState<TableFilters>(defaultTableFilters);

    const onCompleted = useCallback<(data: LandlordsQuery) => void>((data) => {
        prevPagesCount.current = data.landlords.pagination.totalPages;
    }, []);

    const { data, loading } = useLandlordsQuery({
        variables: {
            take,
            page,
            email: filter.variant === SearchField.Email ? filter.value : undefined,
            id: filter.variant === SearchField.Id ? filter.value : undefined,
            authIds: filter.variant === SearchField.SsoId ? [filter.value] : undefined,
        },
        onCompleted: onCompleted,
        fetchPolicy: 'cache-and-network',
    });

    const onSearch = useCallback<TableContextType['onSearch']>((value, variant) => setFilter({ value, variant }), []);

    return {
        rows: data?.landlords.items ?? [],
        pagesCount: prevPagesCount.current,
        loading,
        onSearch,
        filter,
    };
};

export default useLandlords;
