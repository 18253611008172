export enum SearchField {
    Id = 'Id',
    Email = 'Email',
    SsoId = 'SsoId',
}

export interface TableFilters {
    value: string;
    variant: SearchField;
}
